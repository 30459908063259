import { useUserStore } from '@/store/user';

export default defineNuxtRouteMiddleware(async (to, from) => {
  const userStore = useUserStore();
  const toast = useToast()

  if (userStore.loggedIn) {
    if (!userStore.emailConfirmed && !to.meta.skipEmailConfirmation) {
      toast.add({title: "Please confirm your email"})
      return navigateTo('/settings');
    }
  } else {
    if (to.meta.skipAuth) return;

    if (to.path !== '/login') {
      return navigateTo('/login');
    }
  }
});
