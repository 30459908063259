import revive_payload_client_4sVQNw7RlN from "/home/runner/work/favoritely/favoritely/frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/favoritely/favoritely/frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/favoritely/favoritely/frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/runner/work/favoritely/favoritely/frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/runner/work/favoritely/favoritely/frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/runner/work/favoritely/favoritely/frontend/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/favoritely/favoritely/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/favoritely/favoritely/frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_w2VlvAEcpa from "/home/runner/work/favoritely/favoritely/frontend/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import plugin_8SbxDRbG6Y from "/home/runner/work/favoritely/favoritely/frontend/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import plugin_client_RZVNejKJBe from "/home/runner/work/favoritely/favoritely/frontend/node_modules/@samk-dev/nuxt-vcalendar/dist/runtime/plugin.client.js";
import plugin_client_5j40ijIZYu from "/home/runner/work/favoritely/favoritely/frontend/node_modules/@nuxtjs/plausible/dist/runtime/plugin.client.js";
import slideovers_LDumGYo2KH from "/home/runner/work/favoritely/favoritely/frontend/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.mjs";
import modals_bidRKewKK5 from "/home/runner/work/favoritely/favoritely/frontend/node_modules/@nuxt/ui/dist/runtime/plugins/modals.mjs";
import colors_244lXBzhnM from "/home/runner/work/favoritely/favoritely/frontend/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_OVoKJro5pc from "/home/runner/work/favoritely/favoritely/frontend/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/favoritely/favoritely/frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_1UohGbtF8v from "/home/runner/work/favoritely/favoritely/frontend/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import actioncable_vue_284iIzIzNV from "/home/runner/work/favoritely/favoritely/frontend/plugins/actioncable-vue.js";
import appMounted_Ydkfpy6TtK from "/home/runner/work/favoritely/favoritely/frontend/plugins/appMounted.js";
import bus_GHaWeQPCWC from "/home/runner/work/favoritely/favoritely/frontend/plugins/bus.js";
import caProvinces_ESa7x7UR3R from "/home/runner/work/favoritely/favoritely/frontend/plugins/caProvinces.js";
import capitalize_5X0AKU6Qvv from "/home/runner/work/favoritely/favoritely/frontend/plugins/capitalize.js";
import fingerprint_client_Z9SxJPXU9n from "/home/runner/work/favoritely/favoritely/frontend/plugins/fingerprint.client.js";
import fontawesome_cn2c4tOOHz from "/home/runner/work/favoritely/favoritely/frontend/plugins/fontawesome.ts";
import humanizeString_fiRI40xkcf from "/home/runner/work/favoritely/favoritely/frontend/plugins/humanizeString.js";
import maska_UHaKf2z1iQ from "/home/runner/work/favoritely/favoritely/frontend/plugins/maska.ts";
import masonry_UUne9oxs8w from "/home/runner/work/favoritely/favoritely/frontend/plugins/masonry.ts";
import number_animation_QHFv628Kqk from "/home/runner/work/favoritely/favoritely/frontend/plugins/number-animation.js";
import numberToHuman_1YhwUVmueR from "/home/runner/work/favoritely/favoritely/frontend/plugins/numberToHuman.ts";
import qrcode_client_HqU92zcfKi from "/home/runner/work/favoritely/favoritely/frontend/plugins/qrcode.client.js";
import secondsToTime_MSdtU9Fc3N from "/home/runner/work/favoritely/favoritely/frontend/plugins/secondsToTime.ts";
import socketNotifications_RjhipBthbm from "/home/runner/work/favoritely/favoritely/frontend/plugins/socketNotifications.js";
import usStates_aWRTeE9TG4 from "/home/runner/work/favoritely/favoritely/frontend/plugins/usStates.js";
import plugin_auto_pageviews_client_x3Y8XIaaPY from "/home/runner/work/favoritely/favoritely/frontend/node_modules/@nuxtjs/plausible/dist/runtime/plugin-auto-pageviews.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_w2VlvAEcpa,
  plugin_8SbxDRbG6Y,
  plugin_client_RZVNejKJBe,
  plugin_client_5j40ijIZYu,
  slideovers_LDumGYo2KH,
  modals_bidRKewKK5,
  colors_244lXBzhnM,
  plugin_client_OVoKJro5pc,
  chunk_reload_client_UciE0i6zes,
  plugin_1UohGbtF8v,
  actioncable_vue_284iIzIzNV,
  appMounted_Ydkfpy6TtK,
  bus_GHaWeQPCWC,
  caProvinces_ESa7x7UR3R,
  capitalize_5X0AKU6Qvv,
  fingerprint_client_Z9SxJPXU9n,
  fontawesome_cn2c4tOOHz,
  humanizeString_fiRI40xkcf,
  maska_UHaKf2z1iQ,
  masonry_UUne9oxs8w,
  number_animation_QHFv628Kqk,
  numberToHuman_1YhwUVmueR,
  qrcode_client_HqU92zcfKi,
  secondsToTime_MSdtU9Fc3N,
  socketNotifications_RjhipBthbm,
  usStates_aWRTeE9TG4,
  plugin_auto_pageviews_client_x3Y8XIaaPY
]