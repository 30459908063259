export default defineNuxtPlugin((nuxtApp) => {
  const secondsToTime = (num: number): string => {
    if (!num) return null
    if (num >= 3600) {
      return new Date(num * 1000).toISOString().substr(11, 8)
    } else {
      return new Date(num * 1000).toISOString().substr(14, 5)
    }
  }

  nuxtApp.provide("secondsToTime", secondsToTime)
})