import { useUserStore } from "@/store/user"

export async function useLogoutTracking() {
  if (process.client) {
    const config    = useRuntimeConfig()
    const route     = useRoute()
    const userStore = useUserStore()
    const headers   = useRequestHeaders()

    // TODO: Incorporate metadata from fingerprinting library

    try {
      await $fetch(`${config.public.API_URL}/api/events`, {
        method: "POST",
        body: {
          type: 8, // Event::Type::Logout
          creator: userStore.username,
          reference_id: userStore.userId,
          path: route.path,
          value: 1,
          text: "User Logout",
          user_agent: headers["user-agent"],
          ref_domain: headers["referer"] && new URL(headers["referer"]).host,
          ref_path: headers["referer"] && new URL(headers["referer"]).path,
        },
      })
    } catch (e) {
      console.error(e)
    }
  }
}